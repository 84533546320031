import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import {
  HttpClient,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { throwError } from "rxjs";
import { tap } from "rxjs/operators";
import { AlertMessageService } from './alert-message.service';
import { AppProgressBarService } from './app-progress-bar.service';
import { PARAMS } from '@app/config/params';
import * as lodash from 'lodash';
import { MESSAGES } from '@app/config/messages';
import { AuthService } from '@app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient, private appProgressBarService: AppProgressBarService,
    private alertMessageService: AlertMessageService) {

  }

  public get(url: string, options?: any): Observable<any> {
    return this.http
      .get(this.createUrl(url), options)
      .pipe(tap(this.handleSuccess, this.handleError));
  }
  getDataWithoutHeaders(url:string): Observable<any> {
    return this.http.get<any>(url);
  }

  public post(url: string, data: any, options?: any): Observable<any> {
    return this.http
      .post(this.createUrl(url), data, options)
      .pipe(tap(this.handleSuccess, this.handleError));
  }

  public put(url: string, data: any): Observable<any> {
    return this.http
      .put(this.createUrl(url), data)
      .pipe(tap(this.handleSuccess, this.handleError));
  }

  public delete(url: string) {
    return this.http
      .delete(this.createUrl(url))
      .pipe(tap(this.handleSuccess, this.handleError));
  }

  public httprequest(url: string, formData: any, options?: any) {
    const header = new HttpHeaders({ upload: 'true' });

    const req = new HttpRequest('POST', this.createUrl(url), formData, {
      headers: header,
      reportProgress: true
    });

    return this.http
      .request(req)
      .pipe(tap(this.handleSuccess, this.handleError));
  }

  private createUrl(url: string): string {
    return url;
  }

  private handleSuccess(result: any) {
    return result;
  }

  private handleError(error: HttpErrorResponse) {
    let appProgressBarService = new AppProgressBarService();
    let alertMessageService = new AlertMessageService();
    appProgressBarService.stop();
    if (error.status == 401) {
      localStorage.removeItem(AuthService.AUTH_TOKEN);
      alertMessageService.addError('Session expired. Please login again. Redirecting...').show();
      setTimeout(() => {
        window.location.href = '.' + PARAMS.BUYER_EXCEL_CLOUD.URLS.LOGIN;
      }, 2000);
    } else if (error.url.indexOf('/user/auth-data') !== -1 && error.status == 400) {
      alertMessageService.addError('Session expired. Please login again. Redirecting...').show();
      setTimeout(() => {
        window.location.href = '.' + PARAMS.BUYER_EXCEL_CLOUD.URLS.LOGIN;
      }, 2000);
    }
    else if (error.status == 400 && !error.url.includes('moduleName=Download-Restricted')) {
      if (lodash.has(error, 'error.message')) { //error_msg
        alertMessageService.addError(error.error.message).show();
      }
      else {
        alertMessageService.addError(MESSAGES.ERROR.DATA_CHECK_SOMETHING_WENT_WRONG).show();
      }
    }
    else {
      if(!error.url.includes('moduleName=Download-Restricted')) {
        alertMessageService.addError('Something went wrong. Please try again.').show();
      }
    }
    return throwError(error);
  }
}