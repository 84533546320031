import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { PARAMS } from '../config/params';
import { AppProgressBarService } from '@app/services/app-progress-bar.service';
import { AlertMessageService } from './alert-message.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
    private authService: AuthService,
    private alertMessage: AlertMessageService,
    private AppProgressBarService: AppProgressBarService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    let isAuthenticated: Observable<boolean> | boolean = this.authService.isAuthenticated();

    if (!isAuthenticated) {
      const queryParamToken: string = next?.queryParams?.token || "";
      if (queryParamToken) {
        const decodedToken = this.decodeBase64UrlSafe(queryParamToken);
        if (decodedToken && this.isJwtValid(decodedToken)) {
          this.authService.setToken(decodedToken);
        } else {
          this.authService.setToken(null);
        }
      }
      const token = this.authService.getToken();
      if (token) {
        return this.revalidateAuthentication()
      } else {
        this.router.navigate([PARAMS.BUYER_EXCEL_CLOUD.URLS.LOGIN]);
      }
    }
    return isAuthenticated;
  }

  isJwtValid(base64Token: string): boolean { // Checking Decoded Base64 JWT
    try {
      const [header, payload, signature] = base64Token.split('.');
      if (!header || !payload || !signature) {
        return false;
      }
      const decodedPayload = JSON.parse(atob(payload));
      if (!decodedPayload.exp || !decodedPayload.sub) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  decodeBase64UrlSafe(base64UrlSafe: string): string | null {
    try {
      const base64String = base64UrlSafe
        .replace(/-/g, '+')  // Convert '-' to '+'
        .replace(/_/g, '/')  // Convert '_' to '/'
        .replace(/\./g, '='); // Replace '.' with '=' for padding if needed
      return atob(base64String);
    } catch (error) {
      this.alertMessage.addError(`Failed to decode Base64 URL-safe string: ${error.message}`).show();
      return null;
    }
  }


  revalidateAuthentication(): Observable<boolean> {
    return this.authService.buildSessionV1();
  }

}
